import React from "react";
import "./Impressum.css";
import { Footer } from "../components/Vorschaltseite/Footer/Footer";
import { Navbar } from "../components/v1.0/Navbar/Navbar";

function Impressum() {
  return (
    <div className="impressumwrapper">
      <div>
        <Navbar />
        <div className="impressumcontent">
          <h1 className="impressumheading impressumtext">Impressum</h1>
          <div className="impressumtextwrapper">
            <h5 className="impressumtext">Luft&Laune e.V.</h5>
            <p className="impressumtext">
              Am Mittelhafen 42
              <br />
              48155 Münster
              <br />
            </p>
            <p className="impressumtext">
              Vereinsregister des Amtsgerichts Münster
              <br />
              Vereinsregister-Nummer: VR 6250
              <br />
            </p>
            <h6 className="impressumtext">Vertreten durch:</h6>
            <p className="impressumtext">
              Celina Menk
              <br />
              Jannik Buschhaus
              <br />
              Malte Hömberg
              <br />
            </p>
            <h6 className="impressumtext">Postadresse:</h6>
            <p className="impressumtext">
              Luft&Laune e.V.
              <br />
              c/o B-Side
              <br />
              Am Mittelhafen 42
              <br />
              48155 Münster
              <br />
            </p>
            <h6 className="impressumtext">Kontakt:</h6>
            <p className="impressumtext">
              E-Mail: mail@luftundlaune.art
              <br />
              Telefon: +49 1745733797
              <br />
            </p>
            <h6 className="impressumtext">Rechnungsadresse:</h6>
            <p className="impressumtext">
              Luft&Laune e.V.
              <br />
              Am Mittelhafen 42
              <br />
              48155 Münster
              <br />
            </p>
            <p className="impressumtext">
              Rechnungen bitte an finanzen@luftundlaune.art
              <br />
            </p>
          </div>
          <a className="button" href="mailto:mail@luftundlaune.art">
            <p className="buttontext white">Kontaktiere uns via Mail</p>
            <i className="icon-arrow-right white"></i>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Impressum;
